import React from 'react';
import { Link } from 'gatsby';
import injectSheet from 'react-jss';
import i18next from '../config/i18next';
import { isChinaUser, isCDCUser } from '../config/isChina';

import Translate from './Translate';
import Consent from './consent';
import themeColors from '../styles/theme';

const ListLink = (props) => {
    const anchor = props.anchor ? <a href={props.to}>{props.children}</a> : <Link to={props.to}>{props.children}</Link>;
    return <li className={props.className}>{anchor}</li>;
};

const styles = {
    footer: {
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: '#EFEFEF',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '60px 0'
    },
    footerBtn: {
        fontSize: '1.3rem',
        fontWeight: '200',
        paddingBottom: '30px',
        '& a': {
            borderRadius: '3px',
            borderColor: '#555555',
            borderStyle: 'solid',
            borderWidth: '1px',
            padding: '10px'
        },
        '& a:visited, & a:hover, & a:active': {
            color: '#555555'
        }
    },
    copyright: {
        color: themeColors.footerText,
        fontWeight: '300',
        boxSizing: 'border-box',
        flex: 1,
        textAlign: 'left',
        paddingLeft: '20px'
    },
    navigation: {
        boxSizing: 'border-box',
        flex: 1,
        padding: 0,
        textAlign: 'right'
    },
    navigationItem: {
        display: 'inline-block',
        color: themeColors.footerLinks,
        fontWeight: '400',
        '& a:visited, & a:hover, & a:active': {
            color: themeColors.footerLinksHover,
            textDecoration: 'none'
        },
        '&:not(:first-of-type):before': {
            content: '""',
            height: '5px',
            width: '5px',
            backgroundColor: themeColors.footerText,
            borderRadius: '50%',
            display: 'inline-block',
            margin: '0 10px 3px 10px'
        }
    },
    navWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    socialMediaInstagram: {
        backgroundImage: (props) => `url(${props.instaIcon})`,
        width: '24px',
        height: '24px',
        display: 'inline-block',
        margin: '15px'
    },
    socialMediaFacebook: {
        backgroundImage: (props) => `url(${props.facebookIcon})`,
        width: '24px',
        height: '24px',
        display: 'inline-block',
        margin: '15px'
    },
    socialMediaTwitter: {
        backgroundImage: (props) => `url(${props.twitterIcon})`,
        width: '24px',
        height: '24px',
        display: 'inline-block',
        margin: '15px'
    },
    socialMedia: {
        '& a': {
            width: '24px',
            height: '24px',
            display: 'inline-block'
        }
    },
    '@media screen and (max-width:767px)': {
        navWrap: {
            flexWrap: 'wrap',
            padding: '0 5px 0 5px'
        },
        navigation: {
            flexBasis: '100%',
            textAlign: 'center'
        },
        copyright: {
            textAlign: 'center',
            paddingLeft: 0
        }
    }
};

const year = new Date().getFullYear();

/**
 * Footer component
 */
class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLocal: i18next.language || ''
        };
    }

    renderFooterURL() {
        const { currentLocal } = this.state;
        const { classes } = this.props;
        let languageCode = currentLocal;
        // Special cases for Hebrew abf Arabic, as IT has a completely different code for those URL
        if (currentLocal === 'ar-AR') {
            languageCode = 'ar-AE';
        } else if (currentLocal === 'iw-IL') {
            languageCode = 'he-IL';
        } else if (currentLocal === 'it-LT') {
            languageCode = 'lt-LT';
        }

        let rootUrl = 'https://www.garmin.com/';
        if (isCDCUser()) {
            rootUrl = 'https://www.garmin.cn/';
        } else if (isChinaUser()) {
            rootUrl = 'https://wwwus.garmin.cn/';
        }
        rootUrl += languageCode;

        const legalUrl = `${rootUrl}/legal/atdisclaimer/`;
        const termsUrl = `${rootUrl}/legal/terms-of-use/`;
        const privacyUrl = `${rootUrl}/privacy/connect/`;
        const securityUrl = `${rootUrl}/legal/security/`;

        const chinaRegisterLink = isChinaUser() ? (
            <span>
                &nbsp;&nbsp;&nbsp;沪ICP备{' '}
                <a href="http://www.miibeian.gov.cn/">
                    <b>15055224</b>
                </a>{' '}
                号
            </span>
        ) : (
            ''
        );

        return (
            <div>
                <div className={classes.footerBtn}>
                    <a href={legalUrl}>{<Translate ns="main_container" content="activity_tracking_accuracy" />}</a>
                </div>
                <div className={classes.navWrap}>
                    <ul className={classes.navigation}>
                        <ListLink className={classes.navigationItem} to="/support/">
                            {<Translate ns="main_container" content="help.title" />}
                        </ListLink>
                        <ListLink className={classes.navigationItem} to="/status/">
                            {<Translate ns="main_container" content="status_text" />}
                        </ListLink>
                        <ListLink className={classes.navigationItem} anchor={true} to={termsUrl}>
                            {<Translate ns="prelogin_pages" content="terms_label" />}
                        </ListLink>
                        <ListLink className={classes.navigationItem} anchor={true} to={privacyUrl}>
                            {<Translate ns="prelogin_pages" content="privacy_label" />}
                        </ListLink>
                        <ListLink className={classes.navigationItem} anchor={true} to={securityUrl}>
                            {<Translate ns="prelogin_pages" content="security_label" />}
                        </ListLink>
                    </ul>
                    <div className={classes.copyright}>
                        {<Translate ns="main_container" content="connect_copyright_text" params={{ 0: year }} />}
                        {chinaRegisterLink}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        const urls = this.state.currentLocal ? this.renderFooterURL() : '';
        const displaySocialMedia = isChinaUser() ? (
            ''
        ) : (
            <div className={classes.socialMedia}>
                <span className={classes.socialMediaInstagram}>
                    <a title="Instagram" href="https://www.instagram.com/garminfitness/" />
                </span>
                <span className={classes.socialMediaFacebook}>
                    <a title="Facebook" href="https://www.facebook.com/garminfit" />
                </span>
                <span className={classes.socialMediaTwitter}>
                    <a title="Twitter" href="https://twitter.com/garminfitness" />
                </span>
            </div>
        );
        const showConsent = isChinaUser() ? '' : <Consent />;

        return (
            <footer className={classes.footer}>
                <div>{urls}</div>
                {displaySocialMedia}
                {showConsent}
            </footer>
        );
    }
}
export default injectSheet(styles)(Footer);
