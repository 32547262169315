import React from 'react';
import { Link } from 'gatsby';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import backgroundImage from '../data/images/logo-sprite-@2x.png';
import closeIcon from '../data/images/SVG-files/icon-close.svg';
import menuIcon from '../data/images/SVG-files/icon-menu.svg';
import languages from '../data/languages.json';
import Dropdown from './dropdown';
import Translate from './Translate';
import i18next from '../config/i18next';
import { isUserSignedIn } from '../config/isUserSignedIn';
import { getHost } from '../config/getHost';
import Button from '../components/button';

const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 15% 0 15%',
        background: '#1a1a1a',
        height: '60px'
    },
    headerLogo: {
        width: '150px',
        height: '28px',
        display: 'block',
        backgroundSize: '236px 271px',
        background: `transparent url(${backgroundImage}) no-repeat 0 -23.47619px`,
        position: 'relative',
        top: '3px'
    },
    menu: {
        listStyle: 'none',
        float: 'right',
        margin: 0
    },
    menuItem: {
        display: 'inline-block',
        marginRight: '25px',
        '& a': {
            color: '#fff',
            fontSize: '1em',
            fontWeight: '300'
        }
    },
    languageItem: {
        composes: '$menuItem'
    },
    iconPointerDown: {
        width: 0,
        height: 0,
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderTop: '4px solid #fff',
        display: 'inline-block',
        marginLeft: '5px'
    },
    iconPointerUp: {
        width: 0,
        height: 0,
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderBottom: '4px solid #fff',
        display: 'inline-block',
        marginLeft: '5px'
    },
    responsiveMenuItem: {
        display: 'none'
    },
    list: {
        margin: 0,
        padding: 0
    },
    h1: {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        marginBlockStart: '0em',
        marginBlockEnd: '0em'
    },
    rightNavItems: {
        marginTop: '12px'
    },
    leftNavItems: {
        marginTop: '12px'
    },
    // Replace menu items with one hamburger menu
    '@media only screen and (max-width: 767px)': {
        languageItem: {},
        headerLogo: {
            top: '-3px'
        },
        list: {},
        menuItem: {},
        iconPointerDown: {},
        iconPointerUp: {},
        button: {}, // TODO - Remove all empty objects once the following bug is fixed and released from JSS https://github.com/cssinjs/jss/issues/446},
        showFullHeight: {
            height: '100%'
        },
        showViewHeight: {
            height: '100vh'
        },
        header: {
            display: 'flex',
            flexFlow: 'wrap',
            justifyContent: 'space-between',
            padding: 0,
            margin: 0
        },
        rightNavItems: {
            display: 'none'
        },
        responsiveMenuItem: {
            background: `transparent url(${menuIcon}) no-repeat`,
            display: 'inline-flex',
            margin: '23px 25px',
            width: '20px',
            height: '14px'
        },
        responsiveMenu: {
            display: 'block',
            flexFlow: 'wrap',
            justifyContent: 'space-between',
            padding: 0,
            margin: 0,
            background: '#121212',
            '& $headerLogo': {
                background: `transparent url(${backgroundImage}) no-repeat 0 -23.47619px`,
                backgroundSize: '236px 271px'
            },
            '& $rightNavItems': {
                flexBasis: '100%',
                display: 'flex',
                margin: '5px 0 10px 0'
            },
            '& $list': {
                margin: 0,
                padding: 0,
                display: 'grid',
                width: '100%'
            },
            '& $menuItem': {
                borderTop: '1px solid #4d4d4d',
                color: '#fff',
                padding: '10px 25px',
                margin: 0,
                '&:nth-last-child(2)': {
                    borderBottom: '1px solid #4d4d4d'
                },
                '& a': {
                    color: '#fff'
                }
            },
            '& $iconPointerDown': {
                float: 'right',
                marginTop: '10px',
                borderTop: '4px solid #fff'
            },
            '& $iconPointerUp': {
                float: 'right',
                marginTop: '10px',
                borderBottom: '4px solid #fff'
            },
            '& $button': {
                justifySelf: 'center',
                width: '80%',
                marginTop: '20px',
                marginRight: 0,
                borderTop: '0px',
                textAlign: 'center'
            },
            '& $responsiveMenuItem': {
                background: `transparent url(${closeIcon}) no-repeat `,
                width: '20px',
                height: '18px',
                display: 'inline-block',
                position: 'absolute',
                right: '0'
            },
            '& $languageItem': {
                margin: 0,
                padding: 0,
                '& div': {
                    padding: '10px 25px'
                },
                '& ul': {
                    maxHeight: 'initial'
                },
                '& li': {
                    color: '#000',
                    padding: '10px 25px'
                }
            }
        },
        leftNavItems: {
            flexBasis: '50%',
            display: 'inline-flex',
            padding: '10px 25px'
        }
    }
};

const ListLink = (props) => (
    <li className={props.className}>
        <Link to={props.to}>{props.children}</Link>
    </li>
);

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enableResponsiveMenu: false,
            showLanguageDropdown: false,
            languageSelection: languages.find((language) => language.locale === i18next.language),
            isTranslationLoaded: Object.keys(i18next.store.data).length || false
        };
        // create a ref to store the textInput DOM element
        this.navigation = React.createRef();
        this.showResponsiveMenu = this.showResponsiveMenu.bind(this);
        this.renderLanguageDropdown = this.renderLanguageDropdown.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    componentDidMount() {
        const self = this;
        i18next.on('loaded', function(loaded) {
            if (loaded[i18next.language]) {
                self.setState({ isTranslationLoaded: true });
            }
        });
    }

    /**
     * showResponsiveMenu - occurs when we need to show the menu on mobile
     *
     * @param  {object} event
     * @return {undefined}
     */
    showResponsiveMenu(event) {
        event.preventDefault();
        const toggleResponsiveMenu = !this.state.enableResponsiveMenu;
        this.setState({ enableResponsiveMenu: toggleResponsiveMenu });
        this.props.toggleResponsiveMode(toggleResponsiveMenu); // Change the background color
    }

    /**
     * renderLanguageDropdown - occurs when the language dropdown is rendered
     *
     * @param  {object} event
     * @return {undefined}
     */
    renderLanguageDropdown(event) {
        event.preventDefault();
        const toggleLanguageDropdown = !this.state.showLanguageDropdown;
        this.setState({ showLanguageDropdown: toggleLanguageDropdown });
    }

    /**
     * changeLanguage - occurs when user selects a language
     *
     * @param  {string} languageKey a string containing the locale of the language selected
     * @return {undefined}
     */
    changeLanguage(languageKey) {
        const that = this;

        i18next.changeLanguage(languageKey, () => {
            that.setState({
                languageSelection: languages.find((language) => language.locale === languageKey)
            });
            window.location.reload();
        });
    }

    /**
     * Render the text on the right side of the header
     **/
    renderHeaderText() {
        const { classes } = this.props;
        const { showLanguageDropdown, languageSelection } = this.state;

        const iconPointerClass = showLanguageDropdown ? classes.iconPointerUp : classes.iconPointerDown;

        const selectedLanguage =
            languageSelection && languageSelection.language ? languageSelection.language : i18next.fallbackLng;

        const buttonText = isUserSignedIn() ? (
            <Translate ns="main_container" content="main_header_dashboard_text" />
        ) : (
            <Translate ns="prelogin_pages" content="sign_in" />
        );

        const buttonLink = isUserSignedIn() ? (
            <li className={classes.menuItem}>
                <a href={`${getHost()}/modern/`}>
                    <Button>{buttonText}</Button>
                </a>
            </li>
        ) : (
            <ListLink to="/signin" className={classes.menuItem}>
                <Button>{buttonText}</Button>
            </ListLink>
        );

        const renderSignOutText = (
            <li className={classes.menuItem}>
                <a href={`${getHost()}/modern/auth/logout`}>
                    <Translate ns="main_container" content="main_header_sign_out_text" />
                </a>
            </li>
        );

        const languageDropdown = (
            <li className={classes.languageItem} onClick={this.renderLanguageDropdown}>
                <div>
                    <a href="#">{selectedLanguage}</a>
                    <i className={iconPointerClass} />
                </div>
                <Dropdown
                    list={languages}
                    show={this.state.showLanguageDropdown}
                    changeSelection={this.changeLanguage}
                />
            </li>
        );

        return (
            <nav className={classes.rightNavItems}>
                <ul className={classes.list}>
                    <ListLink className={classes.menuItem} to="/start/">
                        <Translate ns="segment" content="get_started" />
                    </ListLink>
                    <ListLink className={classes.menuItem} to="/support/">
                        <Translate ns="device" content="device.support" />
                    </ListLink>
                    {!isUserSignedIn() && languageDropdown}
                    {isUserSignedIn() && renderSignOutText}
                    {buttonLink}
                </ul>
            </nav>
        );
    }

    render() {
        const { classes } = this.props;
        const { enableResponsiveMenu, showLanguageDropdown } = this.state;
        const height = enableResponsiveMenu && showLanguageDropdown ? classes.showFullHeight : classes.showViewHeight;
        let menuClass = enableResponsiveMenu ? classNames(classes.responsiveMenu, height) : classes.header;
        const headerText = this.state.isTranslationLoaded ? this.renderHeaderText() : '';

        return (
            <header className={menuClass} ref={this.navigation}>
                <h1 className={`${classes.h1} ${classes.leftNavItems}`}>
                    <Link className={classes.headerLogo} to="/" title="Connect" />
                </h1>
                <div className={classes.responsiveMenuItem} onClick={this.showResponsiveMenu} />
                {headerText}
            </header>
        );
    }
}

export default injectSheet(styles)(Header);
