import React from 'react';
import injectSheet from 'react-jss';

const styles = {
    dropDownItem: {
        padding: '5px 5px 2px 15px',
        cursor: 'pointer',
        '&:hover': {
            background: '#eeeeee'
        }
    },
    showDropdownContainer: {
        background: '#fff',
        position: 'absolute',
        listStyleType: 'none',
        padding: 0,
        whiteSpace: 'nowrap',
        maxHeight: '50vh',
        overflow: 'scroll',
        margin: '10px 0 0 0',
        zIndex: 1
    },
    hideDropdownContainer: {
        display: 'none'
    },
    '@media only screen and (max-width: 767px)': {
        showDropdownContainer: {
            position: 'relative',
            margin: 0
        }
    }
};

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: ''
        };
        this.itemSelected = this.itemSelected.bind(this);
    }

    /**
     * itemSelected - occurs when an item is selected fromt the dropdown
     *
     * @param  {string} key - the locale from the language object selected
     * @return {undefined}
     */
    itemSelected(key) {
        this.setState({
            selected: key
        });
        this.props.changeSelection(key);
    }

    render() {
        const { classes, list, show } = this.props;
        const isHidden = show ? classes.showDropdownContainer : classes.hideDropdownContainer;

        return (
            <ul className={isHidden}>
                {list.map((item) => (
                    <li
                        className={classes.dropDownItem}
                        key={item.locale}
                        onClick={() => this.itemSelected(item.locale)}
                    >
                        {item.language}
                    </li>
                ))}
            </ul>
        );
    }
}

export default injectSheet(styles)(Dropdown);
